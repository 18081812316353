<header class="border-blue-300 border-b-1">
    <div class="flex justify-between items-center pt-48 px-24 sm:py-30 pb-16 max-w-1200 m-auto">
        <a [routerLink]="'bienvenue'">
            <img class="w-200" src="assets/images/logo.svg" width="200" height="42" alt="MonFuturPro">
        </a>

        <button class="sm:hidden bg-primary rounded-12 w-40 h-40 flex items-center justify-center" type="button" (click)="openMenu()">
            <i class="iconoir-menu text-white text-24"></i>
        </button>

        <div class="hidden sm:flex sm:flex-wrap">
            @for (nav of navigations(); track $index) {
                <a class="flex items-center gap-10 mr-8 py-8 px-12 rounded-5 hover:bg-blue-200" [routerLink]="nav.href" [routerLinkActive]="'bg-secondary-variant'">
                    <i [class]="'text-gray-300 text-17 iconoir-' + nav.picto" [routerLinkActive]="'text-primary'"></i>
                    <span class="font-semibold text-gray-300" [routerLinkActive]="'text-primary'">{{ nav.name }}</span>
                </a>
            }
        </div>
    </div>
</header>

<ng-template #mobileMenu>
    <div class="w-full bg-white overflow-y-auto">
        <div class="flex justify-between items-center pt-48 px-24 pb-16 border-blue-300 border-b-1 m-auto">
            <div>
                <img class="w-165" src="assets/images/logo.svg" alt="MonFuturPro">
            </div>

            <button class="bg-primary rounded-12 w-40 h-40 flex items-center justify-center" type="button" (click)="closeMenu()">
                <i class="iconoir-xmark text-white text-24"></i>
            </button>
        </div>

        <div class="flex flex-col p-32 gap-16">
            @for (nav of mobileNavigations(); track $index) {
                <a class="flex items-center gap-10 mr-8 py-8 px-12 rounded-5" [routerLink]="nav.href" [routerLinkActive]="'bg-secondary-variant'">
                    <i [class]="'text-gray-300 text-17 iconoir-' + nav.picto" [routerLinkActive]="'text-primary'"></i>
                    <span class="font-semibold text-gray-300 text-14" [routerLinkActive]="'text-primary'">{{ nav.name }}</span>
                </a>
            }
        </div>

        <div class="border-blue-300 border-b-1 mx-32"></div>

        <div class="flex flex-col p-32 gap-16">
            <a class="flex items-center justify-center gap-12" routerLink="recherche" uiButton [color]="'primary'">
                <i class="text-white text-20 iconoir-search" [routerLinkActive]="'text-primary'"></i>
                <span class="text-white font-bold text-14">Rechercher</span>
            </a>
        </div>
    </div>
</ng-template>