import { Component, OnInit, OnDestroy, TemplateRef, ViewContainerRef, inject, viewChild, computed, PLATFORM_ID, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonDirective } from '@lorient-emploi-front/shared/ui/directives';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subscription, filter } from 'rxjs';
import { SiteSettingsService } from '@lorient-emploi-front/shared/data-access';
import { isPlatformBrowser } from '@angular/common';

interface Navigation {
  picto: string;
  name: string;
  href: string;
};

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    OverlayModule,
    ButtonDirective
  ],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  private overlay = inject(Overlay);
  private viewContainerRef = inject(ViewContainerRef);
  private _router = inject(Router);
  private _siteSettingsService = inject(SiteSettingsService);

  protected mobileMenu = viewChild<TemplateRef<Element>>('mobileMenu');
  protected showMenu = false;

  protected navigations = computed<Navigation[]>(() => {
    const baseNav = [
      {
        picto: 'home-alt-slim',
        name: 'Bienvenue',
        href: 'bienvenue'
      },
      {
        picto: 'globe',
        name: 'Découvrir',
        href: 'decouvrir'
      },
      {
        picto: 'calendar',
        name: 'Agenda',
        href: 'agenda'
      },
      {
        picto: 'map',
        name: 'Carte',
        href: 'carte'
      },
      {
        picto: 'search',
        name: 'Rechercher',
        href: 'recherche'
      },
      ];

    if (this._siteSettingsService.canSignin()) {
      return [
        ...baseNav,
        {
          picto: 'user-plus',
          name: 'Compte professionnel',
          href: 'inscription'
        }
      ]
    }

    return baseNav;
  });

  protected mobileNavigations = computed<Navigation[]>(() => {
    const baseNav = [
      {
        picto: 'home-alt-slim',
        name: 'Bienvenue',
        href: 'bienvenue'
      },
      {
        picto: 'map',
        name: 'Carte',
        href: 'carte'
      },
      {
        picto: 'calendar',
        name: 'Agenda',
        href: 'agenda'
      },
      {
        picto: 'globe',
        name: 'Découvrir',
        href: 'decouvrir'
      },
      ];

    if (this._siteSettingsService.canSignin()) {
      return [
        ...baseNav,
        {
          picto: 'user-plus',
          name: 'Compte professionnel',
          href: 'inscription'
        }
      ]
    }

    return baseNav;
  });

  private _overlayRef = isPlatformBrowser(this.platformId) ? 
    this.overlay.create({
      hasBackdrop: true,
      width: '100vw',
      height: '100vh',
      scrollStrategy: this.overlay.scrollStrategies.block()
    }) : null;

  private _routerSubscription!: Subscription;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this._siteSettingsService.getShowUserCreation();
      
      this._routerSubscription = this._router.events
        .pipe(
          filter(event => event instanceof NavigationStart),
          filter(() => !!this._overlayRef)
        )
        .subscribe(() => {
          this.closeMenu();
        });
    }
  }

  openMenu() {
    const mobileMenu = this.mobileMenu();

    if (mobileMenu) {
      const portal = new TemplatePortal(mobileMenu, this.viewContainerRef);
      this._overlayRef?.attach(portal);
      this._overlayRef?.backdropClick().subscribe(() => this.closeMenu());
    }
  }

  closeMenu() {
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef?.detach();
    }
  }

  ngOnDestroy(): void {
    if(this._routerSubscription) {
      this._routerSubscription.unsubscribe();
    }
  }
}
