<footer class="bg-white pt-72 px-24 pb-56 ">
    <div class="md:max-w-1100 md:flex justify-between m-auto">
        <div class="md:max-w-130">
            <img class="mb-24" src="assets/images/logo.svg" alt="MonFuturPro">
        
            <p class="text-gray-300 text-16 mb-48">
                Le nouvel accélérateur d'avenir du Pays de Lorient-Quimperlé.
            </p>
        </div>
    
        @for (plan of sitePlans; track $index) {
            <div class="mb-48">
                <p class="mb-24 font-semibold text-14 text-black">{{ plan.name }}</p>
    
                @for (link of plan.links; track $index) {
                    <a class="px-8 py-12 block font-semibold text-14 text-gray-300" [routerLink]="link.href">{{ link.name }}</a>
                }
            </div>
        }
    </div>

    <div class="md:max-w-1100 m-auto">
        <div class="mb-24 font-semibold text-14 text-gray-300">
            Conçue pour créer des liens durables entre les jeunes et les employeurs, le projet est porté par : 
            <div class="flex flex-col gap-30 md:flex-row items-center text-14 text-gray-300 mt-10">
                <div class="flex items-center justify-center mb-5">
                    <img class="w-auto h-50" src="assets/images/footer/ml.webp" height="50" width="174" alt="Mission Locale">
                </div>
                en collaboration avec
                <div class="flex items-center justify-center mb-5">
                    <img class="w-auto h-50" src="assets/images/footer/ml-cornouaille.webp" height="50" width="118" alt="Mission Locale Cornouaille">
                </div>
            </div>
        </div>
        <div class="mb-24 font-semibold text-14 text-gray-300">
            Soutenue par : 
            <div class="flex flex-col gap-30 md:flex-row items-center mt-10">
                <div class="text-gray-300 text-12">
                    <div class="flex items-center justify-center mb-5">
                        <img class="w-auto h-50" src="assets/images/footer/europe.webp" height="50" width="234" alt="Europe">
                    </div> 
                </div>
                <div class="text-gray-300 text-12">
                    <div class="flex items-center justify-center mb-5">
                        <img class="w-auto h-50" src="assets/images/footer/lorient.webp" height="50" width="152" alt="Lorient agglomération">
                    </div> 
                </div>
                <div class="text-gray-300 text-12">
                    <div class="flex items-center justify-center mb-5">
                        <img class="w-auto h-50" src="assets/images/footer/quimperle.webp" height="50" width="128" alt="Quimperlé">
                    </div> 
                </div>
                <div class="text-gray-300 text-12">
                    <div class="flex items-center justify-center mb-5">
                        <img class="w-auto h-50" src="assets/images/footer/balvet_bellevue.webp" height="50" width="62" alt="Balvet Bellevue">
                    </div> 
                </div>
                <div class="text-gray-300 text-12">
                    <div class="flex items-center justify-center mb-5">
                        <img class="w-auto h-50" src="assets/images/footer/region_bretagne.svg" height="50" width="50" alt="Région Bretagne">
                    </div> 
                </div>
                <div class="text-gray-300 text-12">
                    <div class="flex items-center justify-center mb-5">
                        <img class="w-auto h-50" src="assets/images/footer/missions_locales.webp" height="50" width="83" alt="Missions Locales">
                    </div> 
                </div>
            </div>
        </div>
    </div>

    <div class="m-auto md:max-w-1100 w-full border-b-1 border-gray-200 mb-32"></div>

    <div class="m-auto md:max-w-1100 flex justify-between">
        <div class="">
            <p class="text-gray-300">© {{ currentYear }}</p>
            <p class="text-gray-300">Mission Locale Réseaux pour l'emploi du Pays de Lorient</p>
        </div>

        <div class="flex gap-16">
            <a href="https://www.facebook.com/Mission.Locale.Pays.Lorient/" target="_blank"><img class="w-48" src="assets/images/facebook.svg" alt="facebook"></a>
            <a href="https://www.instagram.com/missionlocalelorient/" target="_blank"><img class="w-48" src="assets/images/instagram.svg" alt="instagram"></a>
            <a href="https://fr.linkedin.com/company/mission-locale-r-seaux-pour-l-emploi-du-pays-de-lorient" target="_blank"><img class="w-48" src="assets/images/linkedin.svg" alt="linkedin"></a>
        </div>
    </div>
</footer>
