import { Component, computed, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SiteSettingsService } from '@lorient-emploi-front/shared/data-access';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();

  private _siteSettingsService = inject(SiteSettingsService);

  protected canSignin = computed(() => this._siteSettingsService.canSignin().data );

  sitePlans: {
    name: string;
    links: {
      name: string;
      href: string;
    }[]
  }[] = [
    {
      name: 'Navigation',
      links: [
        {
          name: 'Bienvenue',
          href: 'bienvenue'
        },
        {
          name: 'Rechercher',
          href: 'recherche'
        },
        {
          name: 'Carte',
          href: 'carte'
        },
        {
          name: 'Agenda',
          href: 'agenda'
        },
        {
          name: 'Découvrir',
          href: 'decouvrir'
        },
      ]
    },
    {
      name: 'Mentions & Conditions',
      links: [
        {
          name: 'Mentions légales',
          href: 'mentions-legales'
        },
        {
          name: 'Politique de confidentialité',
          href: 'politique-de-confidentialite'
        },
        {
          name: 'Configuration des cookies',
          href: 'bienvenue'
        },
      ]
    },
    {
      name: 'Autres',
      links: [
        {
          name: 'Plan du site',
          href: 'plan-du-site'
        },
        {
          name: 'Nous contacter',
          href: 'contact'
        },
      ]
    },
  ];

  ngOnInit(): void {
    this._siteSettingsService.getShowUserCreation();
  }
}
